import React from "react";
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import Header from "../components/header.js";
import "../sass/norihall.scss";

export const query = graphql`
  {
    prismic {
		  about(uid:"about", lang:"en-us") {
		    title
		    photo
        photoSharp {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100, webpQuality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
		    bio
        inquiries_text
		  }
    }
  }
`


function AboutPage({ data }) {
  const photoURLSharp = data.prismic.about.photoSharp.childImageSharp.fluid;
  return (
    <>
      <Header />
      <div id="content">      
        {RichText.render(data.prismic.about.title)}
        <div id="contentAbout">
          <div className="artist-info">
            <Img fluid={photoURLSharp} alt={data.prismic.about.photo.alt} />
            {RichText.render(data.prismic.about.inquiries_text)}
          </div>
          <div className="artist-bio">
            {RichText.render(data.prismic.about.bio)}
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutPage